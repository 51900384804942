import { render, staticRenderFns } from "./editServiceBookings.vue?vue&type=template&id=0ce09fc1&scoped=true"
import script from "./editServiceBookings.vue?vue&type=script&lang=js"
export * from "./editServiceBookings.vue?vue&type=script&lang=js"
import style0 from "./editServiceBookings.vue?vue&type=style&index=0&id=0ce09fc1&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ce09fc1",
  null
  
)

export default component.exports