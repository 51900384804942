<template>
  <div class='card'>
    <div class='container'>
      <b-modal ref='addServiceBookingsModal' hide-footer>
        <addServiceBookings @closeAddServiceBookings='toggleAddServiceBookings()' @addServiceBookingsSuccess='addServiceBookingsSuccess()'></addServiceBookings>
      </b-modal>
      <b-modal ref='editServiceBookingsModal' hide-footer>
        <editServiceBookings
          :editingServiceBookings='currentServiceBookings'
          @closeEditServiceBookings='toggleEditServiceBookings()'
          @editServiceBookingsSuccess='editServiceBookingsSuccess()'
        ></editServiceBookings>
      </b-modal>
      <div class='row'>
        <h2>ServiceBookings</h2>
      </div>
      <div class='row'>
        <button @click='toggleAddServiceBookings()' type='button' class='btn btn-success'>Add</button>
      </div>
      <div class='row'>
        <br>
        <datatable :dataItems='items' @rowClicked='editItem' :dataColumns='columns' :searching='true' :sorting='true' @editRow='editItem' :order='[1, "dec"]' class='text-center' striped bordered />
      </div>
    </div>
  </div>
</template>
<script>
import addServiceBookings from '../../components/addServiceBookings';
import editServiceBookings from '../../components/editServiceBookings';
import VueNotifications from 'vue-notifications';
import {    Datatable  } from '../../mdb/components/Datatable';
import miniToastr from 'mini-toastr'; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: 'success',
  error: 'error',
  info: 'info',
  warn: 'warn'
};
miniToastr.init({ types: toastTypes });
export default {
  data() {
    return {
      columns: [
              {
                label: 'Id',
                field: 'Id',
                sort: 'asc',
              },
              {
                label: 'Fullname',
                field: 'Fullname',
                sort: 'asc',
              },
              {
                label: 'EmailAddress',
                field: 'EmailAddress',
                sort: 'asc',
              },
              {
                label: 'Cellphone',
                field: 'Cellphone',
                sort: 'asc',
              },
              {
                label: 'PreferredContactMethod',
                field: 'PreferredContactMethod',
                sort: 'asc',
              },
              {
                label: 'ServiceDate',
                field: 'ServiceDate',
                sort: 'asc',
              },
              {
                label: 'RequestDate',
                field: 'RequestDate',
                sort: 'asc',
              },
              {
                label: 'VehicleModel',
                field: 'VehicleModel',
                sort: 'asc',
              },
              {
                label: 'VINNumber',
                field: 'VINNumber',
                sort: 'asc',
              },
              {
                label: 'OptionalInstructions',
                field: 'OptionalInstructions',
                sort: 'asc',
              },
              {
                label: 'Dealarship',
                field: 'Dealarship',
                sort: 'asc',
              },
              {
                label: 'LastUpdated',
                field: 'LastUpdated',
                sort: 'asc',
              },
      ],
      items: [],
      addServiceBookingsOpen: false,
      editServiceBookingsOpen: false,
      currentServiceBookings: {}
    };
  },
  components: {
    addServiceBookings,
    editServiceBookings,
    Datatable,
  },
  created: function() {
    this.getServiceBookings();
    this.addServiceBookingsOpen = false;
    this.editServiceBookingsOpen = false;
  },
  methods: {
    getImageSource(data) {
      if (!this.isNull(data) && !this.isNull(data.Image)) {
        return this.$store.state.cdnUrl + data.Image;
      }
      return '';
    },
    isNull: function(obj) {
      if (typeof obj === 'undefined' || obj === null || obj === 'null') {
        return true;
      }
      return false;
    },
 getServiceBookings() {
      var payload = {
        success: response => {
          this.items = response.data;
        },
        error: error => { miniToastr['error'](error, 'Error', 1000, null)}
      }
      this.$store.dispatch('getServiceBookings', payload);
    },
    editItem(ServiceBookings) {
      this.toggleEditServiceBookings();
      this.currentServiceBookings = ServiceBookings;
    },
    toggleAddServiceBookings() {
      if(this.addServiceBookingsOpen)
      {
        this.$refs.addServiceBookingsModal.hide()
      }
      else{
        this.$refs.addServiceBookingsModal.show()
      }
      this.addServiceBookingsOpen = !this.addServiceBookingsOpen;
    },
    addServiceBookingsSuccess() {
      this.toggleAddServiceBookings();
      miniToastr['success']('ServiceBookings Added', 'Success', 1000, null);
      this.getServiceBookings();
    },
    toggleEditServiceBookings() {
      if(this.editServiceBookingsOpen)
      {
        this.$refs.editServiceBookingsModal.hide()
      }
      else{
        this.$refs.editServiceBookingsModal.show()
      }
      this.editServiceBookingsOpen = !this.editServiceBookingsOpen;
    },
    editServiceBookingsSuccess() {
      this.toggleEditServiceBookings();
      miniToastr['success']('ServiceBookings Edited', 'Success', 1000, null);
      this.getServiceBookings();
    }
  }
};
</script>
<style scoped>
.ServiceBookingsThumbnail {
  height: 50px;
}
</style>
