<template>
  <div class='container'>
    <h2>Edit ServiceBookings</h2>
    <button @click='save()' type='button' class='btn btn-success'>Save</button>
    
    <button @click='toggleView()' type='button' class='btn btn-warning'>Cancel</button>
    
    <button @click='deleteServiceBookings()' type='button' class='btn btn-danger'>Delete</button>
	
	<div class='row'>
<div class='col-md-4'>
   <label class for='Fullname'>Fullname</label>
   <input
     class='form-control'
     id='Fullname'
     name='Fullname'
     v-model='editingServiceBookings.Fullname'
     placeholder=' '
     tabindex='0'
     type='text'
   >
	
 </div>
<div class='col-md-4'>
   <label class for='EmailAddress'>EmailAddress</label>
   <input
     class='form-control'
     id='EmailAddress'
     name='EmailAddress'
     v-model='editingServiceBookings.EmailAddress'
     placeholder=' '
     tabindex='0'
     type='text'
   >
	
 </div>
<div class='col-md-4'>
   <label class for='Cellphone'>Cellphone</label>
   <input
     class='form-control'
     id='Cellphone'
     name='Cellphone'
     v-model='editingServiceBookings.Cellphone'
     placeholder=' '
     tabindex='0'
     type='text'
   >
	
 </div>
<div class='col-md-4'>
   <label class for='PreferredContactMethod'>PreferredContactMethod</label>
   <input
     class='form-control'
     id='PreferredContactMethod'
     name='PreferredContactMethod'
     v-model='editingServiceBookings.PreferredContactMethod'
     placeholder=' '
     tabindex='0'
     type='text'
   >
	
 </div>
<div class='col-md-4'>
   <label class for='ServiceDate'>ServiceDate</label>
   <input
     class='form-control'
     id='ServiceDate'
     name='ServiceDate'
     v-model='editingServiceBookings.ServiceDate'
     placeholder=' '
     tabindex='0'
     type='text'
   >
	
 </div>
<div class='col-md-4'>
   <label class for='RequestDate'>RequestDate</label>
   <input
     class='form-control'
     id='RequestDate'
     name='RequestDate'
     v-model='editingServiceBookings.RequestDate'
     placeholder=' '
     tabindex='0'
     type='text'
   >
	
 </div>
<div class='col-md-4'>
   <label class for='VehicleModel'>VehicleModel</label>
   <input
     class='form-control'
     id='VehicleModel'
     name='VehicleModel'
     v-model='editingServiceBookings.VehicleModel'
     placeholder=' '
     tabindex='0'
     type='text'
   >
	
 </div>
<div class='col-md-4'>
   <label class for='VINNumber'>VINNumber</label>
   <input
     class='form-control'
     id='VINNumber'
     name='VINNumber'
     v-model='editingServiceBookings.VINNumber'
     placeholder=' '
     tabindex='0'
     type='text'
   >
	
 </div>
<div class='col-md-4'>
   <label class for='OptionalInstructions'>OptionalInstructions</label>
   <input
     class='form-control'
     id='OptionalInstructions'
     name='OptionalInstructions'
     v-model='editingServiceBookings.OptionalInstructions'
     placeholder=' '
     tabindex='0'
     type='text'
   >
	
 </div>
<div class='col-md-4'>
   <label class for='Dealarship'>Dealarship</label>
   <input
     class='form-control'
     id='Dealarship'
     name='Dealarship'
     v-model='editingServiceBookings.Dealarship'
     placeholder=' '
     tabindex='0'
     type='text'
   >
	
 </div>
<div class='col-md-4'>
   <label class for='LastUpdated'>LastUpdated</label>
   <input
     class='form-control'
     id='LastUpdated'
     name='LastUpdated'
     v-model='editingServiceBookings.LastUpdated'
     placeholder=' '
     tabindex='0'
     type='text'
   >
	
 </div>
  </div>
    <br/>
    <button @click='save()' type='button' class='btn btn-success'>Save</button>
    <button @click='toggleView()' type='button' class='btn btn-warning'>Cancel</button>
    <br/>
    <br/>
    </div>
    
</template>
<script>
import _ from 'lodash'; 
import FileUploader from '@/components/custom/FileUploader.vue'; 
import miniToastr from 'mini-toastr'; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: 'success',
  error: 'error',
  info: 'info',
  warn: 'warn'
};
miniToastr.init({ types: toastTypes });
 
export default { 
  name: 'EditServiceBookings', 
 
  data() { 
    return { 
      imageBytes: {}, 
      selectedFile: {}, 
      originalData: {}, 
      modifiedData: {}, 
        uploadedImageData: {}, 
        uploadedImageUrl: null, 
      editingImage: false 
    }; 
  }, 
  components: { 
    FileUploader 
  }, 
  created() { 
    this.originalData = JSON.parse(JSON.stringify(this.editingServiceBookings)); 
    this.ImageBytes = this.editingServiceBookings.Image; 
  }, 
 
  props: ['editingServiceBookings'], 
 
  methods: { 
    toggleView: function() { 
      this.$emit('closeEditServiceBookings'); 
    }, 
 
    save: function() { 
    var data = JSON.parse(JSON.stringify(this.editingServiceBookings));
      var payload = {
          data: data,
          image: this.uploadedImageData,
          success: response => {
            this.$emit('editServiceBookingsSuccess') 
          },
          error: error => { miniToastr['error'](error, 'Error', 1000, null)}
        }
        this.$store.dispatch('editServiceBookings', payload);
    }, 
	updateImageData: function(value) { 
      this.uploadedImageData = value; 
    }, 
	updateImageUrl: function(value) {  
      this.uploadedImageUrl = value; 
    }, 
    editImage: function() { 
      this.editingImage = !this.editingImage; 
    }, 
    updateImage: function() { 
      this.editingImage = !this.editingImage; 
    }, 
    getImageSource(data) {
      if(this.uploadedImageUrl)
      {
        return this.uploadedImageUrl;
      }
        if (!this.isNull(data) && !this.isNull(data.Image)) {
        if (data.Image) {
            return this.$store.state.cdnUrl + data.Image;
        }
        else {
            return data.Image;
        }
      }
      return '';
    },
    isNull: function(obj) {
      if (typeof obj === 'undefined' || obj === null || obj === 'null') {
        return true;
      }
      return false;
    },
    deleteServiceBookings: function() { 
     var payload = {
      data: this.editingServiceBookings,
      success: response => {
        this.$emit('editServiceBookingsSuccess') 
      },
      error: error => { miniToastr['error'](error, 'Error', 1000, null)}
    }
    this.$store.dispatch('deleteServiceBookings', payload);
    } 
  } 
}; 
</script> 
<style scoped> 
.previewImage { 
  max-height: 100px; 
} 
</style> 
